import React, { useState, useEffect, useLayoutEffect } from 'react';

import { IDocumentsPage, IDocument } from '@/types/strapi/documents';

import Theme from '../../images/theme.inline.svg';
import { mediaUrl, formatText } from '../../utils';
import { TLocale } from '../../utils/i18n';
import DocumentsList from '../DocumentsList';
import Footer from '../footer';
import Heading from '../heading';
import Layout from '../layout';
import PageSection from '../PageSection';


import './index.css';

import { IGlobalServerRuntimeData } from '@/types/strapi/global';


interface IServerRuntimeData extends IGlobalServerRuntimeData {
    documentsPage: IDocumentsPage,
    documents: Array<IDocument>
}

interface IProps {
    data: IServerRuntimeData,
    lang?: TLocale
}

function getDefaultTheme(): boolean {
    const theme = window.localStorage.getItem('theme');
    return theme === 'dark';
}

const DocumentsPage = ({ data, lang }: IProps) => {
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    useEffect(() => {
        if(isDarkTheme) {
            document.querySelector('.app-layout')?.classList.add('dark');
        } else {
            document.querySelector('.app-layout')?.classList.remove('dark');
        }
        window.localStorage.setItem('theme', isDarkTheme ? 'dark' : 'light');
    }, [isDarkTheme]);

    useLayoutEffect(() => {
        const isDark = getDefaultTheme();

        setIsDarkTheme(isDark);

        if(isDark) {
            document.querySelector('.app-layout')?.classList.add('dark');
        }
    }, [setIsDarkTheme]);

    return (
        <Layout
            lang={lang}
            seo={{
                ...data.documentsPage.seo,
                shareImage: data.documentsPage.seo?.shareImage?.url ? mediaUrl(data.documentsPage.seo?.shareImage?.url) : undefined
            }}
            hasContactButton={true}
            navPanel={data.navPanel}
            global={data.global}
            respondForm={data.respondForm}
        >
            <PageSection className="document-page__heading" removeAboveOffset={true} removeBelowOffset={true}>
                <Heading className="document-page__heading-title" level={1} as="h1" dangerouslySetInnerHTML={{ __html: formatText(data.documentsPage.headerText) }} />
                <Theme className="document-page__heading-theme" onClick={() => setIsDarkTheme(!isDarkTheme)} />
            </PageSection>
            <PageSection className="document-page__list" removeAboveOffset={true} removeBelowOffset={true}>
                <DocumentsList data={data.documents} />
            </PageSection>
            <Footer lang={lang} className="document-page__footer" data={data.footer} />
        </Layout>
    );
};

export default DocumentsPage;

import React from 'react';

import { useClassnames } from '../../hooks/use-classnames';
import { IFooter } from '../../types/strapi/global';
import FooterDisclamer from '../FooterDisclamer';

import './index.css';

import { useAppContext } from '../../context/context';
import { TLocale } from '../../utils/i18n';


interface IProps {
    data: IFooter,
    header?: string,
    className?: string,
    lang?: TLocale
}

const rootClassName = 'main-footer';

const Footer = ({ className, data, header: headerFromProps, lang }: IProps) => {
    const cn = useClassnames();
    const { isVisibleFeedbackForms } = useAppContext();

    return (
        <footer className={cn(rootClassName, className)}>
            {/* {isVisibleFeedbackForms && <FooterTitle header={headerFromProps || data.header} modalLinkText={data.modalLinkText} />}*/}
            <FooterDisclamer lang={lang} data={data} />
        </footer>
    );
};

export default Footer;

import React from 'react';

import Arrow from '../../images/document-hover-arrow.inline.svg';
import Substrate from '../../images/document-substrate.inline.svg';
import { mediaUrl } from '../../utils';

import './index.css';

import { IDocument } from '@/types/strapi/documents';

interface IProps {
    document: IDocument
}

const DocumentCard = ({ document }: IProps) => {
    return (
        <a href={mediaUrl(document.url || document.file.url, document.getFromLocal)} target="_blank" className="document-card">
            <div className="document-card__wrapper">
                <p className="document-card__title" dangerouslySetInnerHTML={{ __html: document.title }} />
                <p className="document-card__type" dangerouslySetInnerHTML={{ __html: document.type }} />
            </div>
            <Arrow className="document-card__arrow" />
            <Substrate className="document-card__substrate" />
        </a>
    );
};

export default DocumentCard;

import React, { useMemo } from 'react';

import DocumentCard from '../DocumentCard';

import './index.css';

import { IDocument } from '@/types/strapi/documents';

interface IProps {
    data: Array<IDocument>
}

const DocumentsList = ({ data }: IProps) => {
    const docsWithPrivacyPolicyDoc = useMemo((): Array<IDocument> => [
        {
            file: {
                url: '/docs/privacy_policy.pdf'
            },
            url         : '/docs/privacy_policy.pdf',
            title       : 'Политика конфиденциальности',
            type        : 'PDF',
            getFromLocal: true
        },
        {
            file: {
                url: '/docs/Cookie_Policy.pdf'
            },
            url         : '/docs/Cookie_Policy.pdf',
            title       : 'Политика обработки файлов Cookies и аналитика нашего сайта',
            type        : 'PDF',
            getFromLocal: true
        },
        ...data
    ], [data]);

    return (
        <ul className="document-list">
            { docsWithPrivacyPolicyDoc.map((document, index) => <DocumentCard key={index} document={document} />) }
        </ul>
    );
};

export default DocumentsList;
